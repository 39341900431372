import React, { useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import StepWizard from "react-step-wizard";
import Personal from "./Personal";
import PickupTrip from "./PickupTrip";
import ReturnTrip from "./ReturnTrip";

const MainForm = () => {
  const history = useHistory();

  const [formData, setFormData] = useState({
    service: "",
    paddress: "",
    daddress: "",
    rpaddress: "",
    rdaddress: "",
    name: "",
    instructions: "",
    phone: "",
    email: "",
    surname: "",
    car: "",
    passengers: "",
    date: "",
    time: "",
    trip: "",
    luggages: "",
    flight: "",

    rcar: "",
    rpassengers: "",
    rdate: "",
    rtime: "",
    rluggages: "",
    rflight: "",
  });
  const {
    service,
    name,
    instructions,
    phone,
    email,
    surname,
    trip,

    paddress,
    daddress,
    car,
    passengers,
    date,
    time,
    luggages,
    flight,

    rdaddress,
    rpaddress,
    rcar,
    rpassengers,
    rdate,
    rtime,
    rluggages,
    rflight,
  } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    const newBooking = {
      service,
      paddress,
      daddress,
      rdaddress,
      rpaddress,

      name,
      instructions,
      phone,
      email,
      surname,
      trip,

      car,
      passengers,
      date,
      time,
      luggages,
      flight,

      rcar,
      rpassengers,
      rdate,
      rtime,
      rluggages,
      rflight,
    };
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify(newBooking);
      const res = await axios.post("/api/booking/quote", body, config);

      console.log(res.data);
      // console.log(newBooking);
    } catch (err) {
      console.error(err.response.data);
    }
    history.push("/success-quote");
  };
  return (
    <div>
      <section id="airport" class=" text-white py-15 py-xl-20">
        <div className="container">
          <h2 className="text-center mb-30"> Book your Limo </h2>
          <h5 className="text-center mb-25">
            {" "}
            Need urgent assistance? Call (415) 810-2923{" "}
          </h5>
          <hr />
          <StepWizard isHashEnabled={true}>
            <Personal
              hashKey={"step1"}
              changeFunc={onChange}
              service={service}
              name={name}
              instructions={instructions}
              phone={phone}
              email={email}
              surname={surname}
              trip={trip}
            />
            <PickupTrip
              hashKey={"step2"}
              changeFunc={onChange}
              paddress={paddress}
              daddress={daddress}
              car={car}
              passengers={passengers}
              date={date}
              time={time}
              luggages={luggages}
              flight={flight}
              trip={trip}
              submitFunc={onSubmit}
            />
            {trip === "Round trip" ? (
              <ReturnTrip
                hashKey={"step3"}
                changeFunc={onChange}
                rdaddress={rdaddress}
                rpaddress={rpaddress}
                rcar={rcar}
                rpassengers={rpassengers}
                rdate={rdate}
                rtime={rtime}
                rluggages={rluggages}
                rflight={rflight}
                submitFunc={onSubmit}
              />
            ) : (
              ""
            )}
          </StepWizard>
        </div>
      </section>
    </div>
  );
};

export default MainForm;
