import React from "react";

const Feature2 = () => {
  return (
    <div>
      <section class="py-20">
        <div class="container">
          <div class="row align-items-center justify-content-center justify-content-lg-between">
            <div class="col-lg-6 col-xl-5 mb-5 mb-lg-0 inverted">
              <h2 class="mb-5">Luxury Service and Professional Drivers</h2>
              <ul class="list-group list-group-minimal">
                <li class="list-group-item d-flex align-items-center">
                  Visiting San Francisco for a conference? Planning a wedding or
                  simply want to travel around Bay Area with a professional
                  chauffer? Whatever your plan is we provide high quality ground
                  transportation across the Bay Area.
                </li>
                <li class="list-group-item d-flex align-items-center">
                  Our cars are clean inside and outside. We care about your
                  comfort and do our best to provide amazing transportation
                  experience.
                </li>
              </ul>
            </div>
            <div class="col-lg-6" data-aos="fade-up">
              <figure class="media equal-1-1 shadow">
                <span
                  style={{ backgroundImage: "url('/dist/images/2.jpeg')" }}
                ></span>
              </figure>{" "}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Feature2;
