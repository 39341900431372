import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
const stripePromise = loadStripe(
  "pk_live_51HBuwlH5wzDkpzBCrKpZNSGHHCYAuCsupaOtTLby4QwbuOnknGBLDtrWfeYQEL1j1M2oJ0eiGFA8Q7NFZQsN79oa0098iXfL6J"
);

const Payment = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default Payment;
