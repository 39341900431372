import React from "react";
import { Link } from "react-router-dom";

const MainHero = () => {
  return (
    <div>
      <section class="overflow-hidden inverted border-bottom">
        <div class="py-20 d-flex flex-column container min-vh-100 foreground">
          <div class="row align-items-center justify-content-center justify-content-lg-between my-auto">
            <div class="col-lg-5 mt-10 col-xl-5 text-center text-lg-start foreground mb-10 mb-lg-0">
              <h1 class="display-2 lh-sm">
                Instantly Book Luxury Limos in SF Bay Area.
              </h1>
              <p class="h4 text-secondary my-4 fw-lg-75">
                Majestic Limo is the best limousine service in San Francisco Bay
                Area.
              </p>
              <div class="row justify-content-center justify-content-lg-start g-1">
                <div class="col-auto">
                  <Link to="/reservations" class="btn btn-red rounded-pill">
                    Make a Reservation
                  </Link>
                </div>
                <div class="col-auto">
                  <Link to="/get-quote" class="btn btn-light rounded-pill">
                    Get Quote
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-7 position-relative">
              <div class="card equal-2-1 equal-2-1 overflow-visible level-1">
                <div class="card-wrap">
                  <img
                    class="img-fluid"
                    src="/dist/images/33.png"
                    alt="Image"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  />
                </div>
              </div>
              <img
                class="position-absolute top-50 start-50 translate-middle level-3"
                src="/dist/assets/images/svg/shadow.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MainHero;
