import React, { useState } from "react";
import axios from "axios";
import { useHistory, withRouter } from "react-router-dom";
// MUI Components

// stripe
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
// Util imports

// Custom Components
import CardInput from "./CardInput";

function CheckoutForm() {
  // State
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const handleSubmit = async (event) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const res = await axios.post("/api/charge", { name: name, email: email });

    const clientSecret = res.data["client_secret"];

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: name,
          email: email,
        },
      },
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      history.push("/success");
    }
  };

  return (
    <div>
      <div class="row">
        <div class="py-15 py-xl-20 text-white col-md-6">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h3 class="panel-title text-center">Payment Details</h3>
              <div className="container">
                <p className="mb-5">
                  Payment information is for reference only. You won't be
                  charged until your booking is confirmed by Majestic Limo.
                  After you press Finish Booking, you'll see a panda and our
                  team will reach out to you to confirm your booking and charge.
                </p>
              </div>
            </div>
            <div class="panel-body">
              <div className="container mt-150">
                <div class="form-group">
                  <label for="cardNumber">Name on Card</label>
                  <input
                    class="form-control"
                    placeholder="Name"
                    id="outlined-email-input"
                    type="text"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div class="form-group mt-1">
                  <label for="cardNumber">Email for receipt</label>
                  <input
                    class="form-control"
                    placeholder="Email"
                    id="outlined-email-input"
                    type="text"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <CardInput />
                <div className="">
                  <button
                    color="primary"
                    className="btn btn-red btn-lg btn-block mt-5"
                    role="button"
                    onClick={handleSubmit}
                  >
                    Finish Booking
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CheckoutForm);
