import React, { Component } from "react";

export default class Success extends Component {
  render() {
    return (
      <div>
        <section id="airport" class=" py-15 py-xl-20 text-white">
          <div class="container">
            <h2 className="text-center mt-10">
              {" "}
              Thank you for your reservation. We'll contact you shortly with
              confirmation.
            </h2>
            <div className="col-md-12 mt-5 text-center">
              <img
                src="/dist/images/panda.png"
                alt="Majesticlimosf.com driver"
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}
