import React from "react";

const Header = () => {
  return (
    <div>
      <nav
        id="mainNav"
        class="navbar navbar-expand-lg navbar-sticky bg-black navbar-dark"
      >
        <div class="container">
          <a href="/" class="navbar-brand">
            <h5 className="fw-bolder">MAJESTIC LIMO</h5>
          </a>

          <ul class="navbar-nav navbar-nav-secondary order-lg-3">
            <li class="nav-item d-lg-none">
              <a
                class="nav-link nav-icon"
                href=""
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbar"
                aria-controls="navbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="bi bi-list"></span>
              </a>
            </li>
            <li class="nav-item d-none d-lg-block">
              <a
                href="callto:(415) 810-2923"
                class="btn btn-outline-white rounded-pill ms-2"
              >
                <i class="bi bi-telephone"></i> / <i class="bi bi-envelope"></i>{" "}
                (415) 810-2923
              </a>
            </li>
            <li class="nav-item d-none d-lg-block">
              <a href="/reservations" class="btn btn-red rounded-pill ms-2">
                Make a Reservation
              </a>
            </li>
          </ul>

          <div
            class="collapse navbar-collapse"
            id="navbar"
            data-bs-parent="#mainNav"
          >
            <ul class="navbar-nav">
              <li class="nav-item ">
                <a class="nav-link " href="/#services">
                  Services
                </a>
              </li>
              <li class="nav-item ">
                <a class="nav-link " href="/reservations">
                  Reservations
                </a>
              </li>
              <li class="nav-item ">
                <a class="nav-link " href="/#fleet">
                  Fleet
                </a>
              </li>
              <li class="nav-item ">
                <a class="nav-link " href="/contact">
                  Contact Us
                </a>
              </li>
              <li class="nav-item d-lg-none">
                <a
                  href="callto:(415) 810-2923"
                  class="nav-link btn btn-light text-black mb-2 rounded-pill"
                >
                  <i class="bi bi-telephone"></i> /{" "}
                  <i class="bi bi-envelope"></i>(415) 810-2923
                </a>
              </li>
              <li class="nav-item d-lg-none">
                <a
                  href="/reservations"
                  class="nav-link btn btn-red rounded-pill"
                >
                  Make a Reservation
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
