import React from "react";

const ReturnTrip = ({
  changeFunc,

  previousStep,
  submitFunc,
  rcar,
  rpassengers,
  rdate,
  rtime,
  rluggages,
  rflight,
  rdaddress,
  rpaddress,
}) => {
  return (
    <div>
      <div class="form-row mt-3">
        <div class="form-group col-md-6">
          <h4>Return trip details:</h4>
          <label for="inputAddress">Return Pick up address</label>
          <input
            type="text"
            class="form-control"
            id="inputAddress"
            placeholder="1234 Main St"
            name="rpaddress"
            value={rpaddress}
            onChange={(e) => changeFunc(e)}
          />
        </div>
        <div class="form-group mt-2 col-md-6">
          <label for="inputAddress2">Return Drop off address</label>

          <input
            type="text"
            class="form-control"
            id="inputAddress2"
            placeholder="San Francisco Intl' Airport"
            name="rdaddress"
            value={rdaddress}
            onChange={(e) => changeFunc(e)}
          />
        </div>

        <div class="form-group col-md-4">
          <label for="inputState">Car type</label>
          <select
            id="inputState"
            class="form-control"
            name="rcar"
            value={rcar}
            onChange={(e) => changeFunc(e)}
          >
            <option>Choose...</option>
            <option>Executive Sedan</option>
            <option>SUV</option>
            <option>Limousine</option>
            <option>Sprinter</option>
          </select>
        </div>
        <div className="row">
          <div class="form-group mt-2 col-md-2">
            <div>
              <label for="inputState">Service Date</label>

              <input
                class="form-control"
                type="text"
                placeholder="mm/dd/yyyy"
                id="example-datetime-local-input"
                name="rdate"
                value={rdate}
                onChange={(e) => changeFunc(e)}
              />
            </div>
          </div>
          <div class="form-group mt-2 col-md-2">
            <div>
              <label for="inputState">Time</label>

              <input
                class="form-control"
                type="text"
                placeholder="6:00pm. "
                id="example-datetime-local-input"
                name="rtime"
                value={rtime}
                onChange={(e) => changeFunc(e)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div class="form-group mt-2 col-md-2">
            <label for="exampleInputEmail1">Number of Passengers</label>
            <input
              placeholder="2 people"
              type="text"
              class="form-control"
              id="number"
              name="rpassengers"
              value={rpassengers}
              onChange={(e) => changeFunc(e)}
            />
          </div>
          <div class="form-group mt-2 col-md-2">
            <label for="exampleInputEmail1">Number of Luggages</label>
            <input
              placeholder="2 luggages"
              type="text"
              class="form-control"
              id="number"
              name="rluggages"
              value={rluggages}
              onChange={(e) => changeFunc(e)}
            />
          </div>
          <div class="form-group mt-2 col-md-2">
            <label for="exampleInputEmail1">Flight Number: if Airport</label>
            <input
              placeholder="DL300"
              type="text"
              class="form-control"
              id="number"
              name="rflight"
              value={rflight}
              onChange={(e) => changeFunc(e)}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex flex-row justify-content-start">
        <button
          style={{ marginRight: "15px" }}
          onClick={() => previousStep()}
          className="btn btn-light mt-3 "
        >
          Go back
        </button>

        <button onClick={() => submitFunc()} className="btn btn-primary mt-3">
          Continue to Payment
        </button>
      </div>
    </div>
  );
};

export default ReturnTrip;
