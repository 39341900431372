import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Airport extends Component {
  render() {
    return (
      <div>
        <section id="airport" class=" py-15 py-xl-20">
          <div class="container">
            <h2 className="text-center mb-50 text-white">
              {" "}
              Business Transportation Services{" "}
            </h2>
            <p className="text-white">
              Majestic Limo LLC offers luxury point-to-point ground
              transportation services for individuals and corporate clients in
              San Francisco and the Bay Area. Your private chauffeurs are
              dedicated to providing the smoothest and most professional service
              possible, because everyone deserves VIP treatment. It makes no
              difference if you’re going to a business meeting, a convention, or
              hotel/ spa, our discreet chauffeurs will get you to your final
              destination in a professional manner.
              <br /> <br /> <h4>Are you interested in partnering with us?</h4>
              <h5>
                Email us at{" "}
                <a href="mailto:majesticlimosf@gmail.com">
                  {" "}
                  majesticlimosf@gmail.com
                </a>{" "}
                and our team member will be in touch with you.
              </h5>{" "}
            </p>
            <Link to="/reservations" className="btn btn-primary mt-25 ">
              Make a reservation
            </Link>
          </div>
        </section>
      </div>
    );
  }
}
