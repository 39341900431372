import React from "react";
import Feature1 from "../version2/Feature1";
import Feature2 from "../version2/Feature2";
import MainHero from "../version2/MainHero";
import Services from "../version2/Services";
import Review from "../version2/Review";
import Fleet from "../layout/Fleet";
import About from "../layout/About";

const Landing = () => {
  return (
    <div>
      <MainHero />
      <Services />
      <Feature1 />
      <Feature2 />
      <Fleet />
      <Review />
      <About />
    </div>
  );
};

export default Landing;
