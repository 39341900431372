import React from "react";

const Feature1 = () => {
  return (
    <div>
      <section class="py-20 mx-lg-3 inverted overflow-hidden bg-red">
        <div class="container">
          <div class="row justify-content-center justify-content-lg-between ">
            <div class="col-lg-6 col-xl-5 text-white order-lg-2 mb-5 mb-lg-0">
              <span class="eyebrow text-muted mb-2">
                Why choose Majestic limo
              </span>
              <h2 class="mb-5">
                Majestic Limo is a cut above the rest when it comes to luxury
                transportation.{" "}
              </h2>
              <ul class="list-group list-group-minimal">
                <li class="list-group-item d-flex align-items-start">
                  <div class="icon-box bg-white rounded-circle me-2">
                    <i class="bi bi-check2 text-black"></i>
                  </div>
                  Responsive customer service who treats all clients with VIP
                  status
                </li>
                <li class="list-group-item d-flex align-items-start">
                  <div class="icon-box bg-white rounded-circle me-2">
                    <i class="bi bi-check2 text-black"></i>
                  </div>
                  Immaculately clean variety of vehicles that cater to the
                  occasion (Sedans, SUVs, Limousines and Vans) wwith sustainable
                  business model and professional, reliable drivers
                </li>
                <li class="list-group-item d-flex align-items-start">
                  <div class="icon-box bg-white rounded-circle me-2">
                    <i class="bi bi-check2 text-black"></i>
                  </div>
                  Convenient credit cards payment options, Intuitive online and
                  phone reservation services and a client loyalty program
                </li>
                <li class="list-group-item d-flex align-items-start">
                  <div class="icon-box bg-white rounded-circle me-2">
                    <i class="bi bi-check2 text-black"></i>
                  </div>
                  Majestic Limo donates to kindergartens, schools and
                  environmental organizations in and around the Bay Area
                </li>
              </ul>
            </div>
            <div class="col-lg-5 order-lg-1" data-aos="fade-up">
              <div class="card equal-2-1 equal-lg-1-1 overflow-visible">
                <div class="card-wrap">
                  <img
                    class="img-fluid test"
                    src="/dist/assets/images/app-23.png"
                    alt="Image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Feature1;
