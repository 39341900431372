import React from "react";

const Footer = () => {
  return (
    <div>
      <footer class="py-15 py-xl-20 bg-black inverted border-top">
        <div class="container">
          <div class="row g-4 g-lg-5 mb-10">
            <div class="col-md-6 text-center text-md-start">
              <a href="/" class="navbar-brand">
                <h5 className="fw-bolder">MAJESTIC LIMO LLC.</h5>
              </a>
              <h6>TCP39074</h6>
              <p>430 Point Lobos Ave. apt 3, San Francisco CA 94121</p>
            </div>
            <div class="col-md-6 col-lg-5 text-center text-md-start">
              <ul class="list-unstyled">
                <li class="mb-1">
                  <a href="/#about" class="text-reset text-primary-hover">
                    About
                  </a>
                </li>
                <li class="mb-1">
                  <a href="/#services" class="text-reset text-primary-hover">
                    Services
                  </a>
                </li>
                <li class="mb-1">
                  <a href="/contact" class="text-reset text-primary-hover">
                    Contact
                  </a>
                </li>
                <li class="mb-1">
                  <a
                    href="/business-service"
                    class="text-reset text-primary-hover"
                  >
                    Partnerships
                  </a>
                </li>
                <li>
                  <a href="/privacy" class="text-reset text-primary-hover">
                    Privacy & Terms
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="row align-items-center g-1 g-lg-6 text-muted">
            <div class="col-lg-3 order-lg-1 text-center text-md-start">
              <p class="small">Copyrights © 2022 MAJESTIC LIMO LLC.</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
