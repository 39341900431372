import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Airport extends Component {
  render() {
    return (
      <div>
        <section id="airport" class=" py-15 py-xl-20">
          <div class="container">
            <h2 className="text-center text-white"> Airport Car Service</h2>
            <p className="text-white">
              Majestic Limo specializes in airport transportation services. We
              can arrange safe and reliable rides to and from San Francisco
              International Airport, Oakland International Airport, and San Jose
              International Airports (SFO is our specialty). Our luxury vehicles
              can accommodate individuals as well as groups, children, and
              assistance with your luggage. Choose Majestic Limo to get you
              there with time to spare! <br /> <br />
              <h3>Departures </h3> <br />
              Our chauffeurs are happy to help with any luggage, car seat, or
              additional needs to make your experience to the airport
              comfortable and easy. We make sure to arrive for pick up 10 mins
              in advance to give you spare time. <br /> <br />
              <h3>Arrivals</h3> <br />
              <h4> Curbside Pick Up</h4> <br />
              Our friendly dispatchers monitor your flight status and message
              you upon the plane’s arrival. Chauffeurs will be standing by to
              meet you at your desired pick up location.  <br />
              Domestic arrivals have 45 mins complimentary waiting time
              International arrivals have 1 hour complimentary waiting time{" "}
              <br />
              Fully certified professional chauffeurs. <br /> <br />
              <h4>Meet and Greet Service</h4> <br />
              Would you prefer your driver to meet you in the airport? For an
              additional $20 dollars, we can arrange for our chauffeurs to meet
              you in the airport to safely escort you to the car and assist with
              your luggage. Just provide us ahead of time with a name so that we
              make a custom sign. <br /> <br />
              <h4>Child Car Seats</h4> <br />
              Traveling with kids? No problem. Majestic Limo offers airport car
              service for families and clients traveling with kids. We provide
              different child car seats. <br /> <br />
              <ul>
                <li> Toddler (front facing)</li>
                <li> Infant (rear facing)</li>
                <li>Booster (under 8 years old)</li>
              </ul>{" "}
              <br />
              <h5>*Charges may apply for child car seats.</h5>
              <br />
            </p>
            <Link to="/reservations" className="btn btn-primary mt-25 ">
              Make a reservation
            </Link>
          </div>
        </section>
      </div>
    );
  }
}
