import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Point extends Component {
  render() {
    return (
      <div>
        <section id="airport" class=" text-white py-15 py-xl-20">
          <div class="container">
            <h2 className="text-center"> Point to Point</h2>
            Are you planning to visit a family gathering, an event or a
            conference? Great our point to point service is perfect for you to
            arrive in style. Whether it's one way trip or a round trip, we do
            them all. Let our Professional drivers help you take there safely.
            <br />
            <br />
            <Link to="/reservations" className="btn btn-primary mt-25 ">
              Make a reservation
            </Link>
          </div>
        </section>
      </div>
    );
  }
}
