import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ScrollIntoView from "./ScrollIntoView";
// components

import Airport from "./components/pages/Airport";
import Hourly from "./components/pages/Hourly";
import Business from "./components/pages/Business";
import Tours from "./components/pages/Tours";
import Privacy from "./components/pages/Privacy";
import Contact from "./components/pages/Contact";
import Wedding from "./components/pages/Wedding";
import Payment from "./components/pages/Payment";
import Success from "./components/pages/Success";
import Success2 from "./components/pages/Success2";

// Version2 imports
import Header from "./components/version2/Header";
import Footer from "./components/version2/Footer";
import Landing from "./components/v2pages/Landing";
import Point from "./components/pages/Point";
import MainForm from "./components/layout/steps/MainForm";
import MainForm2 from "./components/layout/quote/MainForm";

function App() {
  return (
    <Router>
      <div className="App">
        <ScrollIntoView>
          <Header />

          <Route exact path="/" component={Landing} />

          <Route exact path="/airport-service" component={Airport} />
          <Route exact path="/hourly-service" component={Hourly} />
          <Route exact path="/business-service" component={Business} />
          <Route exact path="/tour-service" component={Tours} />
          <Route exact path="/point" component={Point} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/reservations" component={MainForm} />

          <Route exact path="/contact" component={Contact} />
          <Route exact path="/wedding-service" component={Wedding} />
          <Route exact path="/payments" component={Payment} />
          <Route exact path="/success" component={Success} />
          <Route exact path="/success-quote" component={Success2} />
          <Route exact path="/get-quote" component={MainForm2} />

          <Footer />
        </ScrollIntoView>
      </div>
    </Router>
  );
}

export default App;
