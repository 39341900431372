import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Fleet extends Component {
  render() {
    return (
      <div>
        <section id="fleet" class="py-20 mx-lg-3 bg-white">
          <div class="container">
            <div class="row justify-content-center">
              <div class="section-title text-center pb-25">
                <h2 class="display-2"> Fleet</h2>
                <h4 className="mb-5 text-secondary">
                  Our Fleet includes high end luxury vehicles from Sedans (Town
                  Cars), SUVs, Vans, and Stretch Limos 
                </h4>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div class="card itemcard mt-3">
                  <img src="/dist/images/escalade.png" class="" alt="..." />
                  <div class="card-body">
                    <h5 class="card-title">Cadillac Escalade </h5>
                    <p class="card-text">Can fit up to 6 people & 4 luggages</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div class="card itemcard mt-3">
                  <img src="/dist/images/chevy.png" class="" alt="..." />
                  <div class="card-body">
                    <h5 class="card-title">Chevy Suburban </h5>
                    <p class="card-text">Can fit up to 6 people & 4 luggages</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div class="card itemcard mt-3">
                  <img src="/dist/images/navigator.png" class="" alt="..." />
                  <div class="card-body">
                    <h5 class="card-title">Lincoln Navigator </h5>
                    <p class="card-text">Can fit up to 6 people & 4 luggages</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div class="card itemcard mt-3">
                  <img src="/dist/images/continental.png" class="" alt="..." />
                  <div class="card-body">
                    <h5 class="card-title">Lincoln Continental </h5>
                    <p class="card-text">Can fit up to 3 people & 2 luggages</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div class="card itemcard mt-3">
                  <img src="/dist/images/limo1.png" class="" alt="..." />
                  <div class="card-body">
                    <h5 class="card-title">Stretch Limousine</h5>
                    <p class="card-text">Capacity 8 passengers</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div class="card itemcard mt-3">
                  <img src="/dist/images/sprinter_big.png" class="" alt="..." />
                  <div class="card-body">
                    <h5 class="card-title">Sprinter</h5>
                    <p class="card-text">Capacity 14 passengers</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
