import React from "react";

const Personal = ({
  changeFunc,
  nextStep,
  service,
  name,
  instructions,
  phone,
  email,
  surname,
  trip,
}) => {
  return (
    <div>
      <div class="form-row mt-3">
        <h4 className="mt-3">Personal Information:</h4>
        <div class="form-group col-md-6">
          <label for="inputState">Select a service type</label>
          <select
            id="inputState"
            class="form-control"
            name="service"
            value={service}
            onChange={(e) => changeFunc(e)}
          >
            <option>Choose...</option>
            <option>Airport pickup/drop off (Curbside pick up)</option>
            <option>
              Airport pickup/drop off (Inside Meet & Greet Service)
            </option>
            <option>Business/Corporate</option>
            <option>Hourly Black Car Service</option>
            <option>Napa Valley & City Tours</option>
            <option>Point to Point</option>
          </select>
        </div>
      </div>
      <div class="form-row mt-3">
        <div class="form-group col-md-6">
          <label for="inputState">Is this a round trip?</label>
          <select
            id="inputState"
            class="form-control"
            name="trip"
            value={trip}
            onChange={(e) => changeFunc(e)}
          >
            <option>Choose...</option>
            <option>One way</option>
            <option>Round trip</option>
          </select>
        </div>
      </div>
      <div class="row mt-3">
        <div class="form-group mt-2 col-md-3">
          <label for="inputEmail4">First Name</label>
          <input
            type="text"
            class="form-control"
            id="firstname"
            name="name"
            value={name}
            onChange={(e) => changeFunc(e)}
          />
        </div>
        <div class="form-group mt-2 col-md-3">
          <label for="inputPassword4">Last Name</label>
          <input
            type="text"
            class="form-control"
            id="lastname"
            name="surname"
            value={surname}
            onChange={(e) => changeFunc(e)}
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="form-group mt-2 col-md-3">
          <label for="email">Email</label>
          <input
            type="email"
            class="form-control"
            id="email"
            name="email"
            value={email}
            onChange={(e) => changeFunc(e)}
          />
        </div>
        <div class="form-group mt-2 col-md-3">
          <label for="phone">Phone Number</label>
          <input
            type="text"
            class="form-control"
            id="phone"
            name="phone"
            value={phone}
            onChange={(e) => changeFunc(e)}
          />
        </div>
      </div>
      <div class="form-group mt-2 col-6">
        <label for="exampleFormControlTextarea1">
          Any special instructions?
        </label>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="2"
          name="instructions"
          value={instructions}
          onChange={(e) => changeFunc(e)}
        ></textarea>
      </div>
      <button onClick={() => nextStep()} className="btn btn-primary mt-3">
        Continue to Trip Details
      </button>
    </div>
  );
};

export default Personal;
