import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Airport extends Component {
  render() {
    return (
      <div>
        <section id="airport" class=" py-15 py-xl-20">
          <div class="container text-white">
            <h2 className="text-center "> Hourly Car Service</h2>
            At Majestic Limo, we have flexible hourly rates with access to our
            luxury Sedans (Town Cars) and SUVs. Book us hourly for any special
            occasion, whether it be for prom, a quinceanera, or a convention,
            and our chauffeurs will offer the same impeccable service. Limo
            rentals are based on requested hours, however you can extent rental
            if you need to. 
            <br />
            <br />
            <h4>Hourly rates</h4>
            <ul>
              <li>Make multiple stops</li>
              <li> Rates are for per vehicle </li>

              <li> All fees and tolls are included</li>
              <li> Minimum rental is 3-6 hours depending on vehicle.</li>
              <li> All vehicles are non smoking and clean</li>
              <li> Gratuity is not included </li>
            </ul>
            <br />
            <br />
            <Link to="/reservations" className="btn btn-primary mt-25 ">
              Make a reservation
            </Link>
          </div>
        </section>
      </div>
    );
  }
}
