import React from "react";

const Contact = () => {
  return (
    <div>
      <section class=" py-15 py-xl-20">
        <div class="container">
          <h2 className="text-center text-white mb-30">Contact Us </h2>
          <h3 className="text-center text-white mb-25">
            {" "}
            Need urgent assistance? Call{" "}
            <a href="callto:(415) 810-2923">(415) 810-2923</a>{" "}
          </h3>
          <br />
          <h4 className="text-white">
            If you have any questions or concerns please email us at{" "}
            <a href="mailto:majesticlimosf@gmail.com">
              {" "}
              majesticlimosf@gmail.com
            </a>
          </h4>{" "}
          <h3 className="mt-10 text-white">Address:</h3>
          <h5 className="text-white">
            430 Point Lobos Ave. apt 3, San Francisco CA 94121
          </h5>
          <h5 className=" text-white">Licence number: TCP39074</h5>
        </div>
      </section>
    </div>
  );
};

export default Contact;
