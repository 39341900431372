import React from "react";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <div>
      <section id="about" className="about  mt-5 mb-5">
        <div className="container rounded  bg-red">
          <div class="row justify-content-center">
            <div class="section-title text-center pb-25">
              <h3 class="text-black p-5">About Founders</h3>
            </div>
          </div>
          <div className="container">
            <div class="row justify-content-center">
              <p className="text-light h4">
                Majestic Limo was founded by San Francisco State University
                business graduates Ben Imomkulov and Kudrat Kurbonov. Ben and
                Kudrat have been building upon Majestic Limo as a business
                venture since their time in school together and truly believe in
                its sustainable business strategy. After working for many large
                limo companies both friends decided to establish a unique
                business that commits to focus not only on profit but also focus
                on social and environmental concerns.
              </p>

              <h3 className="mt-5 text-black text-center mb-2">
                Have Questions?{" "}
              </h3>
            </div>
            <div class="d-flex flex-row justify-content-center">
              <h2>
                <a
                  role="button"
                  className="btn btn-light mb-5 rounded-pill text-black"
                  href="callto:4158102923"
                >
                  Call Us now
                </a>
              </h2>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
