import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Airport extends Component {
  render() {
    return (
      <div>
        <section id="airport" class="text-white py-15 py-xl-20">
          <div class="container">
            <h2 className="text-center mb-50">Tour Service</h2>
            Majestic Limo offers unique vineyard tours through Napa and Sonoma
            Valley. Our knowledgeable chauffeurs can navigate you and your party
            through some of the best wineries in the area while you relax in one
            of our luxury vehicles complete with complimentary beverages and
            snacks. 
            <br />
            <br />
            <ul>
              <h4>Executive Sedans (Town Car or similar cars).</h4>
              <br />
              <li> Passengers 3</li>
              <li>Leather interior, innovative technology</li>
              <li> Complimentary soda,water, gums, candies etc</li>
              <li> Climate and music control</li>
              <hr />
              <h4> Executive SUVs (Cadillac Escalade, Lincoln Navigator)</h4>
              <br />
              <li>Passangers 5 or 6</li>
              <li>Black leather, more legroom</li>
              <li> Outside black washed daily</li>
              <li>Complimentary soda,water, gums, candies etc</li>
              <li> Climate and music control</li>
              <hr />
              <h4>Executive Stretch Limo and Vans</h4>
              <li>Passenger 8</li>
              <li>Black leather interior</li>
              <li>Complimentary drinks</li>
              <li>Climate control </li>
            </ul>
            <br />
            <Link to="/reservations" className="btn btn-primary mt-25 ">
              Make a reservation
            </Link>
          </div>
        </section>
      </div>
    );
  }
}
