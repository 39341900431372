import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Wedding extends Component {
  render() {
    return (
      <div>
        <section id="airport" class=" text-white py-15 py-xl-20">
          <div class="container">
            <h2 className="text-center"> Wedding Limousine</h2>
            Want to make your special day extra special? Allow us to give you
            and your wedding party the VIP treatment. Book Majestic Limo for
            your wedding related transportation needs and let us make it a
            memorable experience. Make your wedding a limo service wedding, call
            or book today!
            <br />
            <br />
            <Link to="/reservations" className="btn btn-primary mt-25 ">
              Make a reservation
            </Link>
          </div>
        </section>
      </div>
    );
  }
}
