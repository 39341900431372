import React from "react";

const Review = () => {
  return (
    <div>
      <section class="py-20 border-top inverted">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <h2>Customers Love Majestic Limo</h2>
              <blockquote class="blockquote">
                <span class="lh-1 fs-1 mb-0">"</span>
                <p class="">
                  I don't have enough words to express how pleased I am with the
                  services provided by Majestic Limo. Ben was wonderful and
                  stayed in contact with me, no matter the time. Our flights
                  from LAX experienced delays and cancellations, and Ben
                  constantly stayed in contact and assured me that we would have
                  a vehicle when we arrived. While traveling with my children it
                  is always important for me to have safe and reliable
                  transportation. Thank you to Majestic Limo! I will always use
                  your services when in San Francisco.
                </p>
                <i class="bi bi-star-fill h4 text-yellow"></i>
                <i class="bi bi-star-fill h4  text-yellow"></i>
                <i class="bi bi-star-fill h4 text-yellow"></i>
                <i class="bi bi-star-fill h4 text-yellow"></i>
                <i class="bi bi-star-fill h4 text-yellow"></i>
                <footer class="blockquote-footer">
                  <span class="d-block lh-1 text-black">Dennia G.</span>
                  <small>Happy customer from Los Angeles</small>
                </footer>
              </blockquote>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <blockquote class="blockquote">
                <span class="lh-1 fs-1 mb-0">"</span>
                <p class="">
                  Great, professional service. Such a pleasure to do business
                  with! Thank you for accommodating our last minute changes and
                  making my daughter's birthday really memorable. Thank you so
                  much Ben!
                </p>
                <i class="bi bi-star-fill h4 text-yellow"></i>
                <i class="bi bi-star-fill h4  text-yellow"></i>
                <i class="bi bi-star-fill h4 text-yellow"></i>
                <i class="bi bi-star-fill h4 text-yellow"></i>
                <i class="bi bi-star-fill h4 text-yellow"></i>
                <footer class="blockquote-footer">
                  <span class="d-block lh-1 text-black">Lili S.</span>
                  <small>Happy customer from Wolf Creek, Temecula, CA</small>
                </footer>
              </blockquote>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <blockquote class="blockquote">
                <span class="lh-1 fs-1 mb-0">"</span>
                <p class="">
                  Fantastic experience. I'd give six stars if I could.
                  Beautiful, clean car (Lincoln Continental) and Stan was just
                  the perfect driver. Highly recommend!
                </p>
                <i class="bi bi-star-fill h4 text-yellow"></i>
                <i class="bi bi-star-fill h4  text-yellow"></i>
                <i class="bi bi-star-fill h4 text-yellow"></i>
                <i class="bi bi-star-fill h4 text-yellow"></i>
                <i class="bi bi-star-fill h4 text-yellow"></i>
                <footer class="blockquote-footer">
                  <span class="d-block lh-1 text-black">Mark S.</span>
                  <small>Happy customer from San Francisco, CA</small>
                </footer>
              </blockquote>
              <a
                target="_blank"
                className="btn btn-red rounded-pill"
                href="https://www.yelp.com/biz/majestic-limo-san-francisco-3"
              >
                <i class="bi bi-star-fill text-yellow"></i> All Yelp Reviews
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Review;
