import React from "react";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <div>
      <section id="services" class="py-15 py-xl-20 border-top inverted">
        <div class="container">
          <div class="row justify-content-center">
            <div class="section-title text-center">
              <h2 class="title">Our Services</h2>
              <h4 className="mb-5">
                When you book Majestic Limo, you are guaranteed the VIP
                treatment. 
              </h4>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-3">
              <div class="mt-4">
                <div class="card">
                  <img
                    src="/dist/images/air.jpeg"
                    class="card-img-top"
                    alt="Bay area airport pickup transportation service"
                  />
                  <div class="mt-2">
                    <h5 className="card-title">Airport Service</h5>

                    <p>
                      Get fast and professional airport transportation service
                      to all major airports in Bay Area *SFO, OAK, SJC
                    </p>

                    <Link to="/airport-service" className="btn btn-red">
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class=" mt-4">
                <div class="card">
                  <img
                    src="/dist/images/point.jpg"
                    class="card-img-top"
                    alt="Bay area airport pickup transportation service"
                  />
                  <div class="mt-2">
                    <h5 className="card-title">Business/Corporate</h5>

                    <p>
                      We offer hassle-free car services for all of your business
                      transportation needs. Book now.
                    </p>

                    <Link to="/business-service" className="btn btn-red">
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class=" mt-4">
                <div class="card">
                  <img
                    src="/dist/images/time.jpg"
                    class="card-img-top"
                    alt="Bay area airport pickup transportation service"
                  />
                  <div class="mt-2">
                    <h5 className="card-title">Hourly/As Directed</h5>

                    <p>
                      Set up a desired destination and our professional
                      chauffeurs will take care of you.
                    </p>

                    <Link to="/hourly-service" className="btn btn-red">
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class=" mt-4">
                <div class="card">
                  <img
                    src="/dist/images/wed.jpg"
                    class="card-img-top"
                    alt="Bay area airport pickup transportation service"
                  />
                  <div class="mt-2">
                    <h5 className="card-title">Wedding Limousine</h5>

                    <p>
                      Book Majestic Limo for your wedding transportation needs
                      and let us make it a memorable experience.
                    </p>

                    <Link to="/wedding-service" className="btn btn-red">
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div class="col-md-3">
              <div class=" mt-4">
                <div class="card">
                  <img
                    src="/dist/images/napa.jpg"
                    class="card-img-top"
                    alt="Bay area airport pickup transportation service"
                  />
                  <div class="mt-2">
                    <h5 className="card-title"> Napa Valley and City Tours</h5>

                    <p>
                      Majestic Limo offers tours to Napa Valley and Sonoma
                      county. Contact us to learn more.
                    </p>

                    <Link to="/tour-service" className="btn btn-red">
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class=" mt-4">
                <div class="card">
                  <img
                    src="/dist/images/p2p.png"
                    class="card-img-top"
                    alt="Bay area airport pickup transportation service"
                  />
                  <div class="mt-2">
                    <h5 className="card-title"> Point To Point</h5>

                    <p>
                      Get from point A to point B in style. Whether you are
                      attending an event or a conference, we're here to help.
                    </p>

                    <Link to="/point" className="btn btn-red">
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
